<route>
{
  "name": "login",
  "meta": {
    "title": "Login",
    "auth": false
  }
}
</route>

<template>
  <div class="flex-column flex-grow">
    <div class="wrapper">
      <ui-form
        class="form"
        :model="values"
        :rules="rules"
        :hideRequiredMark="true"
        @form="form = $event"
        @validate="handleValidate"
        @submit.prevent="handleSubmit"
      >
        <div v-if="isAccountExist" class="mb-32">
          <img src="@/assets/images/Emoji.svg" alt="emoji" class="mb-24" />
          <div class="title1 mb-8">{{$t('login.account_has_already_exist')}}</div>
          <div class="text3">{{$t('login.seems_like_you_ve_already')}}<br />{{$t('login.just_log_in')}}</div>
        </div>
        <div v-else>
          <div class="image-wrapper">
            <div class="image-container"></div>
          </div>
          <div class="title1 custom-title">{{$t('login.welcome_back')}}</div>
        </div>
        <ui-form-item label="Your E-mail" prop="email" class="form-item">
          <ui-email-input id="email" placeholder="ex. example@gmail.com" v-model="values.email" @input="handleInput" />
        </ui-form-item>
        <div class="relative">
          <ui-link class="forgot-password-link" to="/reset-password-step1" tabindex="-1">
            {{$t('login.forgot_password')}}
          </ui-link>
          <ui-form-item label="Your password" prop="password" class="form-item">
            <ui-password-input id="password" placeholder="required" v-model="values.password" @input="handleInput" />
          </ui-form-item>
        </div>
      </ui-form>
    </div>
    <div class="center">
      <ui-button
        @click="handleSubmit"
        :disabled="btnDisabled || awaitingLoginResponse"
        :awaitingResponse="awaitingLoginResponse"
        >{{$t('login.log_in')}}</ui-button
      >
      <copyright>{{$t('login.by_continuing_you_agree_to')}}</copyright>
      <div class="text2">
        {{$t('login.don_t_have_an_account')}}
        <ui-link to="/register">{{$t('login.sign_up')}}</ui-link>
      </div>
    </div>
  </div>
</template>

<script>
import mapFields from '../utils/mapFields';
import promiseValidateFields from '../utils/promiseValidateFields';
import { enableAllValidationTriggers } from '../utils/enableAllValidationTriggers';
import UiEmailInput from '../components/ui/UiEmailInput';
import UiPasswordInput from '../components/ui/UiPasswordInput';
import UiButton from '../components/ui/UiButton';
import UiForm from '../components/ui/UiForm';
import UiFormItem from '../components/ui/UiFormItem';
import UiLink from '../components/ui/UiLink';
import Copyright from '../components/Copyright';

export default {
  layout: 'auth',
  name: 'login',
  components: {
    UiButton,
    UiPasswordInput,
    UiEmailInput,
    UiForm,
    UiFormItem,
    UiLink,
    Copyright
  },
  head: {
    title: 'kjjjjgin',
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: 'Home page description'
      }
    ]
  },
  data() {
    return {
      isAccountExist: false,
      form: {},
      values: {
        email: '',
        password: ''
      },
      // Triggers will be removed on cred error
      rules: {
        email: [
          {
            trigger: 'blur',
            required: true,
            // eslint-disable-next-line no-useless-escape
            pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            whitespace: true,
            message: 'Please, enter a valid email'
          },
          {
            validator: (rule, value, callback) => {
              if (this.invalidCredentials) {
                callback(new Error(' '));
              } else {
                callback();
              }
            }
          }
        ],
        password: [
          {
            trigger: 'blur',
            required: true,
            min: 8,
            message: 'Must be at least 8 characters'
          },
          {
            validator: (rule, value, callback) => {
              if (this.invalidCredentials) {
                callback(new Error('Email or password is incorrect. Please, try again'));
              } else {
                callback();
              }
            }
          }
        ]
      },

      btnDisabled: true,
      invalidCredentials: false,
      awaitingLoginResponse: false,
      invalidCredentialsError: false,
      hadValidationError: false
    };
  },
  computed: {
    ...mapFields({
      user: 'user'
    })
  },
  created() {
    const { alreadyExistEmail } = this.$route.query;

    if (alreadyExistEmail) {
      this.isAccountExist = true;
      this.values.email = alreadyExistEmail;
    }
  },
  methods: {
    redirect() {
      if (this.$route.params.nextUrl) {
        this.$router.push(this.$route.params.nextUrl);
      } else {
        this.$router.push('/');
      }
    },

    async handleValidate() {
      if (this.invalidCredentialsError) {
        this.invalidCredentialsError = false;
        await promiseValidateFields(this.form.validate, true);
        return;
      }

      this.btnDisabled = this.form.fields.reduce((result, field) => {
        const validationState = field.validateState === 'error';
        if (!this.hadValidationError && validationState) {
          this.hadValidationError = true;
          enableAllValidationTriggers(this.rules);
        }

        return !field.fieldValue || validationState || result;
      }, false);
    },

    async handleSubmit() {
      try {
        this.awaitingLoginResponse = true;
        await promiseValidateFields(this.form.validate);

        const authResult = await this.$store.dispatch('auth/authenticate', {
          strategy: 'local',
          ...this.values
        });
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'setUserID', authResult?.user?.id);
        /*
          No need to set button awaiting animation to false after request is successful
          because we want to avoid flickering during redirection
        */
        this.redirect();
      } catch (err) {
        this.awaitingLoginResponse = false;
        if (err.code === 401) {
          // Invalid credentials error
          this.invalidCredentials = true;
          await promiseValidateFields(this.form.validate, true);
          this.invalidCredentials = false;
          this.invalidCredentialsError = true;
          this.$notify.error('Invalid credentials!');
        } else {
          this.$notify.parseFeathersErrors(err);
        }
      }
    },

    handleInput() {
      if (this.invalidCredentials || this.invalidCredentialsError) {
        this.invalidCredentials = false;
        this.invalidCredentialsError = false;
        this.form.validate();

        /**
         * Remove blur trigger for enabling both (blur & change).
         * It is default way of working of ant form-item.
         */

        Object.values(this.rules).forEach((values) => {
          values.forEach((rule) => {
            // eslint-disable-next-line no-param-reassign
            delete rule.trigger;
          });
        });
      }
    },

    resetForm() {
      this.form.resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.form {
  width: 100%;
  min-height: 507px;
}

.image-wrapper {
  position: relative;
  height: 213px; // 203 in figma for content
  margin-top: 4px;
}

.image-container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url('~@/assets/images/art704.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  display: flex;
  align-items: flex-end;
}

.custom-title {
  margin-top: 40px;
  margin-bottom: 32px;
}

.relative {
  position: relative;
}

.forgot-password-link {
  position: absolute;
  right: 0px;
  z-index: 10;
  font-size: 14px;
}
</style>
